import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";

import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import ContactPerson from "../../../components/contactPerson";
import { GatsbyImage } from "gatsby-plugin-image";
import Hero from "../../../components/hero";
import { CaseList } from "../../../components/caseList.en";

import doodle from "../../../assets/images/services/cms.svg";

const ContentManagementSystems = () => {
  const images = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "services/hero-cms.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      dirk: file(relativePath: { eq: "profiles/dirk-schroedter.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <Layout lang="en" langPath="service.cms.link">
      <Seo
        title="Your digital success with a CMS – Monday Consulting"
        description="As certified implementation partners for content management systems (CMS) from Crownpeak and CoreMedia, we can turn our long experience into your digital advantage."
        keywords="Content management systems, CoreMedia, Crownpeak, FirstSpirit, CMS, Strapi"
      />
      <div className="parallax-translate bg-shape-vertigo"></div>
      <Hero
        headline="A blueprint for success"
        subline="Matching your requirements to the right CMS sets your business on a firm footing."
        section="Content management systems"
      >
        <GatsbyImage
          image={images.hero.childImageSharp.gatsbyImageData}
          className="hero-image"
          alt="Content management systems"
        />
      </Hero>
      <div className="container mt-3 mb-5 mb-md-6">
        <div className="row align-items-start">
          <div className="col-md-6 d-none d-sm-block">
            <img
              className="w-75"
              alt="The starting-point for your digital business"
              src={doodle}
            />
          </div>
          <div className="col-md-6">
            <h2>The starting-point for your digital business</h2>
            <p>
              From greenfield projects to relaunches or complex migrations, as
              certified implementation partners for content management systems
              (CMS) from Crownpeak and CoreMedia, we can turn our long
              experience into your digital advantage. Our experts bring together
              their expertise in strategy, design and development to take your
              customer communications to the next level, while also seeking to
              maximise the security, availability and performance of your
              applications. Whether you’re thinking about cloud or on-prem
              hosting, our solution is always matched perfectly to your needs.
            </p>
            <p>
              As your CMS implementation partner, we provide you with support
              from start to finish, offering both remote and on-site assistance.
              Our extensive service portfolio covers strategy, conceptual
              design, development, integration and project management, and help
              is always at hand from our Customer Care Support team even after
              your successful go-live.
            </p>
          </div>
        </div>
      </div>

      <div className="container mb-md-0">
        <div
          className="row vertical-spacing mb-3 reveal"
          data-spacing-bottom="details"
        >
          <h2 className="col-12 text-center">
            Content management systems in detail
          </h2>
        </div>
        <div className="row align-items-start">
          <div
            className="reveal col-11 col-md-5 mb-4 mb-md-6 bg-serviceicon-cmsexpertise bg-position-tl bg-size-50 vertical-offset"
            id="details"
            data-offset="50%"
          >
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              CMS expertise
            </h3>
            <p>
              Thanks to our long-standing experience in implementing content
              management systems, we can always identify the ideal solution,
              whatever your needs. Since 2009, we’ve been customizing complex
              enterprise systems based on CoreMedia and FirstSpirit, turning the
              existing CMS into a turnkey solution that perfectly reflects
              customer requirements.
            </p>
          </div>

          <div className="reveal col-11 col-md-5 offset-1 pb-4 pb-md-0 mb-4 mb-md-6 bg-serviceicon-coremedia bg-position-tl bg-size-50">
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              CoreMedia
            </h3>
            <p>
              As a CoreMedia Gold and Certified Partner, we develop
              made-to-measure platforms to help your business succeed. We get
              your information moving while networking people and processes with
              this agile content management system that gives us all the
              flexibility and freedom we need to implement your project brief.
            </p>
            <Link
              to="/en/services/cms/coremedia-partnership"
              className="btn mt-1"
            >
              CoreMedia Partnership
            </Link>
          </div>

          <div
            className="reveal col-11 col-md-5 mb-4 mb-md-6 offset-md-1 bg-serviceicon-firstspirit bg-position-tl bg-size-50 vertical-offset"
            data-offset="50%"
          >
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              FirstSpirit
            </h3>
            <p>
              As a Crownpeak Premier Partner, we’re also in the vanguard of
              global consultants for the FirstSpirit Digital Experience Platform
              (DXP) and look forward to providing you with our specialized
              expertise as an Implementation Partner, whatever the complexity of
              your project.
            </p>
            <Link
              to="/en/services/cms/crownpeak-partnership"
              className="btn mt-1"
            >
              FirstSpirit Partnership
            </Link>
          </div>

          <div className="reveal col-11 col-md-5 pb-4 pb-md-0 mb-4 offset-1 bg-serviceicon-strapi bg-position-tl bg-size-50">
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Strapi
            </h3>
            <p>
              Alongside our two enterprise CMS solutions, the market also offers
              a number of lighter-weight alternatives, which include all the
              latest features and are easy to adapt to your needs. Our favourite
              here is the headless CMS Strapi, which our experts can use to get
              your modern CMS platform off the ground in no time at all.
            </p>
          </div>
        </div>
      </div>

      <div
        className="content-container vertical-spacing bg-monday-skyblue bg-pattern-vertigo text-color-white text-center parallax-background"
        data-spacing-bottom="cases"
      >
        <div className="container pt-4 pt-md-5 pb-3 reveal">
          <div className="row mb-3">
            <div className="col-12 d-flex flex-column align-items-center">
              <h3 className="mb-3">Selected case studies</h3>
            </div>
          </div>
        </div>
      </div>
      <div
        id="cases"
        className="container vertical-offset reveal mb-md-6 mb-5"
        data-offset="50%"
      >
        <CaseList name="eppendorf,dak-gesundheit" />
      </div>

      <ContactPerson
        headline="How can we help you today?"
        text="After browsing our impressive portfolio of services, why not contact us to find out more? Advice and assistance is just a phone call away."
        contactEmail="business@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="Dirk Schrödter, Chief Executive"
      >
        <GatsbyImage
          image={images.dirk.childImageSharp.gatsbyImageData}
          alt="Dirk Schrödter"
        />
      </ContactPerson>
    </Layout>
  );
};

export default ContentManagementSystems;
